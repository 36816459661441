import styled from 'styled-components';

import { Button, Flex, Text } from '@pancakeswap/uikit';
import Page from 'components/Layout/Page';
import { useTranslation } from '@pancakeswap/localization';


const NotFound = () => {
    const { t } = useTranslation()

    return (
        <Page>
            <Container style={{ position: "relative" }}>
                <Flex flexDirection="column" width="100%" height="100vh" justifyContent="center" alignItems="center">
                    <img src="/images/goldbridge/page-404.png?version=1.0" alt="logo" />
                    <Text>{t("We are sory for the inconvenience. It looks like you're trying to access a page that has been deleted or never even existed.")}</Text>
                    <CustomButton as="a" href="/">
                        {t('Back to home')}
                    </CustomButton>
                </Flex>
            </Container>
        </Page>
    )
};

export default NotFound;

const Container = styled.div`
    width: 100%;
    max-width: 1300px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    flex-direction: column;
    ${Text}{
        max-width: 500px;
        text-align: center;
    }
`

const CustomButton = styled(Button)`
    width: 216px;
    height: 48px;
    background: ${({ theme }) => theme.colors.primaryBright};
    border-radius: 12px;
    font-weight: 700;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    z-index: 10;
    text-transform: uppercase;
`